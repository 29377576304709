@font-face {
    font-family: "RedHatDisplayRegular";
    src: local("RedHatDisplay-Regular.ttf"),
        url("assets/fonts/RedHat/RedHatDisplay-Regular.ttf") format("truetype");
    font-weight: normal;
    font-display: swap;
}

@font-face {
    font-family: "RedHatDisplayBold";
    src: local("RedHatDisplay-Bold.ttf"),
        url("assets/fonts/RedHat/RedHatDisplay-Bold.ttf") format("truetype");
    font-weight: bold;
    font-display: swap;
}

@font-face {
    font-family: "RedHatDisplayLight";
    src: local("RedHatDisplay-Light.ttf"),
        url("assets/fonts/RedHat/RedHatDisplay-Light.ttf") format("truetype");
    font-weight: 200;
    font-display: swap;
}

@tailwind base;
@tailwind components;
@tailwind utilities;